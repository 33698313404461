import * as Sentry from '@sentry/react';
import {
  ignoreErrors,
  denyUrls,
  beforeSend,
  maxValueLength,
} from 'shared/variables';
window['Sentry'] = Sentry;

Sentry.init({
  dsn: 'https://0f7c5cabc4b04c18b73f7b7cb3b662a4@sentry.io/131996',
  enabled: process.env.NODE_ENV === 'production',
  release: __VERSION__,
  environment: process.env.NODE_ENV,
  ignoreErrors,
  denyUrls,
  beforeSend,
  maxValueLength,
  // integrations: [new ApmIntegrations.Tracing()],
  tracesSampleRate: 0.01,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: ['bookem'],

      // Defines how to handle errors that contain third party stack frames.
      // Possible values are:
      // - 'drop-error-if-contains-third-party-frames'
      // - 'drop-error-if-exclusively-contains-third-party-frames'
      // - 'apply-tag-if-contains-third-party-frames'
      // - 'apply-tag-if-exclusively-contains-third-party-frames'
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
});
